import ProjectCard from "./ProjectCard";
import datome from "../media/projects/datome.png"
import mbs from "../media/projects/mbs.png"
import chainkeeper from "../media/projects/chainkeeper.png"
import aipex from "../media/projects/aipex.png"
import vy from "../media/projects/vy.png"
import plc from "../media/projects/plc.png"
import ten from "../media/projects/ten.png"
import enel from "../media/projects/enelxx.png"
import vite from "../media/projects/vite.png"
import esther from "../media/projects/esther.png"
import crypto from "../media/projects/crypto.png"
import todo from "../media/projects/todo.png"
import mov from "../media/projects/mov.png"
import ecomm from "../media/projects/ecomm.png"
import pin from "../media/projects/pin.png"
import fox from "../media/projects/fox.png"
import sgd from "../media/projects/sgd.png"
import ofp from "../media/projects/ofp.png"

const projects = [
  {
    image: ofp,
    title: "OFP Funding",
    description: "Trading company specializing in instant funding and trading solutions for traders.",
  },
  {
    image: mbs,
    title: "Mangrovia Blockchain Solutions",
    description: "WEB3 solutions provider transforming data management for modern businesses.",
  },
  {
    image: datome,
    title: "Datome",
    description: "Platform as a Service (PaaS) for data modeling and governance, powered by blockchain technology.",
  },
  {
    image: enel,
    title: "Enel X Accenture",
    description: " Collaborative software platform delivering global sustainable energy solutions.",
  },
  {
    image: vy,
    title: "Vysiogen",
    description: "WEB3 Digital marketing and software services agency driving business growth and innovation.",
  },
  {
    image: chainkeeper,
    title: "Chainkeeper",
    description: "Blockchain integration platform offering API-driven solutions for diverse business needs.",
  },
  {
    image: aipex,
    title: "AiPEX Staff",
    description: "Staffing and recruiting agency streamlining the hiring process for employers and candidates.",
  },
  {
    image: plc,
    title: "Puppy Lyfe Co",
    description: "eCommerce platform dedicated to providing quality products for pets and their owners.",
  },
  {
    image: ten,
    title: "Traslochi Easy Nello",
    description: "Home improvement services company specializing in hassle-free relocations.",
  },
  {
    image: vite,
    title: "Vite Trasformate",
    description: "Non-profit Christian organization spreading the gospel and aiding those in need.",
  },
  {
    image: sgd,
    title: "Scrub Gun Deluxe",
    description: "eCommerce platform offering a range of innovative home products.",
  },
  {
    image: esther,
    title: "EstherLeads",
    description: "Lead generation agency specializing in digital marketing for medspas and beauty professionals.",
  },
  {
    image: crypto,
    title: "Crypto Oracles",
    description: "NFT collection and community hub for WEB3 news, events, and blockchain insights.",
  },
  {
    image: fox,
    title: "NFT Collection",
    description: "Blockchain platform enabling the creation, trading, and collection of digital art NFTs.",
  },
  {
    image: todo,
    title: "To Do List app",
    description: "Vibrant task management app designed to boost productivity and organization.",
  },
  {
    image: mov,
    title: "Movie app",
    description: "Streamlined platform offering comprehensive information on top films for movie enthusiasts.",
  },
  {
    image: ecomm,
    title: "eCommerce",
    description: "Intuitive online shopping platform with seamless browsing and user experience.",
  },
  {
    image: pin,
    title: "Amazon clone",
    description: "eCommerce clone mirroring Amazon’s functionalities and user interface.",
  },
/*   {
    image: am,
    title: "Pinterest clone",
    description: "Creative social media app for discovering, saving, and sharing ideas through pins.",
  }, */
];

const ProjectList = () => {
  return (
    <div id="projects" className="mt-28">
      <h1 className="text-center text-3xl font-bold text-success">Projects I Worked On</h1>
    <div className="flex justify-center mt-12">
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 p-4 max-w-screen-lg">
      {projects.map((project, index) => (
        <ProjectCard
          key={index}
          image={project.image}
          title={project.title}
          description={project.description}
        />
      ))}
    </div>
    </div>
    </div>
  );
};

export default ProjectList;