export const Education = () => {
  return (
    <>
      {/* main div */}
      <div id="education" className="mt-28">
        <h1 className="text-center text-3xl font-bold text-accent">
          Education
        </h1>

        {/* singular div */}
        <div className="m-12 sm:px-28">
          {/* Flex container to manage layout */}
          <div className="flex flex-col">
            {/* Badges will be on top on mobile, and at the end on desktop */}
            <div className="flex space-x-2 mb-2 sm:mb-0 sm:mt-2">
              <div className="badge badge-primary badge-outline">Vue.js</div>
              <div className="badge badge-accent badge-outline">JS</div>
              <div className="badge badge-success badge-outline">Typescript</div>
              <div className="badge badge-warning badge-outline">HTML/CSS</div>
            </div>
            {/* h1 element */}
            <h1 className="font-bold text-lg sm:text-start">
              Frontend Developer @ Vue School | 2022
            </h1>
          </div>
          <p>
            Vue.js School learning Vue.js framework, Nuxt, Pinia, Vuex, JavaScript, Typescript, Tailwind CSS, Git/GitHub 
          </p>
        </div>

        {/* singular div */}
        <div className="m-12 sm:px-28">
          {/* Flex container to manage layout */}
          <div className="flex flex-col">
            {/* Badges will be on top on mobile, and at the end on desktop */}
            <div className="flex space-x-2 mb-2 sm:mb-0 sm:mt-2">
              <div className="badge badge-primary badge-outline">Angular</div>
              <div className="badge badge-accent badge-outline">JS</div>
              <div className="badge badge-success badge-outline">Typescript</div>
              <div className="badge badge-warning badge-outline">HTML/CSS</div>
            </div>
            {/* h1 element */}
            <h1 className="font-bold text-lg sm:text-start">
              Frontend Developer Certificate by EpiCode School, Coding Bootcamp
              | 2021
            </h1>
          </div>
          <p>
            Intensive Coding Bootcamp learning: Angular, JavaScript, Typescript,
            HTML, CSS, SASS, Bootstrap, Node.js, jQuery, Postman, Git, BitBucket
          </p>
        </div>

        {/* singular div */}
        <div className="m-12 sm:px-28">
          <div className="flex flex-col">
            <div className="flex space-x-2 mb-2 sm:mb-0 sm:mt-2">
              <div className="badge badge-primary badge-outline">React</div>
              <div className="badge badge-accent badge-outline">JavaScript</div>
              <div className="badge badge-warning badge-outline">HTML/CSS</div>
            </div>
            <h1 className="font-bold text-lg sm:text-start">
              Frontend Development @ Code Academy | 2021
            </h1>
          </div>
          <p>
            Digital Coding Academy learning React, Redux, JavaScript, HTTP
            Requests, HTML, CSS, SASS, Git, GitHub
          </p>
        </div>

        {/* singular div */}
        <div className="m-12 sm:px-28">
          <div className="flex flex-col">
            <div className="flex space-x-2 mb-2 sm:mb-0 sm:mt-2">
              <div className="badge badge-primary badge-outline">React</div>
              <div className="badge badge-accent badge-outline">JavaScript</div>
              <div className="badge badge-warning badge-outline">HTML/CSS</div>
            </div>
            <h1 className="font-bold text-lg sm:text-start">
              Frontend Development Techdegree @ Treehouse | 2020
            </h1>
          </div>
          <p>
            Online Techdegree learning React, JavaScript, HTML, CSS, SASS, Ajax,
            Bootstrap, Git, GitHub
          </p>
        </div>

        {/* singular div */}
        <div className="m-12 sm:px-28">
          <h1 className="font-bold text-lg">
            International Economics @ Boselli Technical Institute | 2010
          </h1>
          <p>Business Administration & International relations (Tourism)</p>
        </div>
      </div>
    </>
  );
};
